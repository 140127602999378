import React, { useState } from 'react';

interface HeaderProps {
  showDeleteButton?: boolean;
  onDeleteAccount?: () => void;
}

const Header: React.FC<HeaderProps> = ({ showDeleteButton = false, onDeleteAccount }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  var arg = new URLSearchParams(window.location.search);

  const handleDeleteAccount = () => {
    setShowConfirmationModal(true);
  };

  const confirmDelete = () => {
    onDeleteAccount?.(); // Trigger onDeleteAccount callback if provided
    setShowConfirmationModal(false); // Close the confirmation modal
  };

  const cancelDelete = () => {
    setShowConfirmationModal(false); // Close the confirmation modal
  };

  return (
    <header className="bg-[#FFFFFF] text-white py-2 h-18 border-b-[1px] border-[#D1D1D1]">
      <div className="container ml-8 flex items-center justify-between" 
        style={{ marginLeft: '6rem'}} >
        <img src={"https://" + ((arg.get('s') === "khutbah") ? "khutbahcaptioning" : "conferencecaptioning") + ".com/images/logo-dark.png"} alt="Logo" className="h-10 mb-2" />
        {showDeleteButton  && (
          <>
          
            
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
