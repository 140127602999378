import React,{useState,useEffect, useRef} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';
import Person from '@mui/icons-material/Person'
import Dashboard from '@mui/icons-material/Dashboard'
import { X, Menu } from "lucide-react"; // Using better icons
import { EventType } from '@testing-library/react';

interface SidebarProps {
  activeItem: 'overview' | 'profile';
}

const Sidebar: React.FC<SidebarProps> = ({ activeItem }) => {
  const navigate = useNavigate();

  const location = useLocation();
  const sidebarRef = useRef(null);
  const closeButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log(`Current active route: ${location.pathname}`);
  }, [location]);

  const isActive = (item: string) => {
    // Assuming your paths are exactly "/overview" and "/profile"
    const currentPath = location.pathname === '/' ? 'overview' : location.pathname.substring(1);
    return currentPath === item ? 'bg-blue-500 text-white' : 'text-gray-700 hover:bg-blue-500 hover:text-white';
  };
   

  const handleLogout = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      console.log('Logout successful');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    if (isMobileMenuOpen) {
      // Focus on close button when menu opens
      closeButtonRef.current?.focus();

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          toggleMobileMenu();
        }
      };

      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isMobileMenuOpen, toggleMobileMenu]);

  return (
    <>
      {/* className="lg:hidden text-4xl text-blue-500 hover:text-blue-700 lg:p-4 absolute top-[0%] left-[1%]" */}
      {/* Hamburger Menu Button */}
      <button
        onClick={toggleMobileMenu}
        className="lg:hidden fixed top-4 left-4 z-50 text-blue-500 hover:text-blue-700 focus:ring-2 focus:ring-blue-300 p-2"
        aria-label="Open menu"
        aria-expanded={isMobileMenuOpen}
        aria-controls="sidebar-menu"
      >
        <Menu size={32} />
      </button>

      {/* Sidebar */}
      <aside
        id="sidebar-menu"
        ref={sidebarRef}
        className={`bg-white shadow-lg z-50 fixed inset-y-0 left-0 w-64 border-r border-gray-300 transition-transform duration-300 ease-in-out transform ${
          isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 lg:static lg:z-auto lg:w-64`}
        role="navigation"
        aria-label="Sidebar"
      >
        {/* Close Button for Mobile */}
        <div
          ref={closeButtonRef}
          onClick={toggleMobileMenu}
          className="lg:hidden absolute top-4 right-4 text-gray-600 hover:text-gray-900 focus:ring-2 focus:ring-gray-300 p-2"
          aria-label="Close menu"
        >
          <X size={24} />
        </div>

        <div className="p-4 flex-1">
          <h2 className="text-xl font-semibold">General</h2>
          <nav className="mt-8">
            <Link to="/" className={`block py-2.5 px-4 rounded ${isActive("overview")}`}>
            🏠 Overview
            </Link>
            <Link to="/profile" className={`block py-2.5 px-4 rounded mt-2 ${isActive("profile")}`}>
            👤 Profile
            </Link>
          </nav>
        </div>
      </aside>

      {/* Overlay when mobile menu is open */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 lg:hidden"
          onClick={toggleMobileMenu}
          aria-hidden="true"
        ></div>
      )}
    </>
  );
};

export default Sidebar;
